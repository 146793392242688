




































































import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { UtilsString } from "@/utils/utils-string";
import { DashColors } from "@/views/Dashboards/Dashcolors";
import { Component, Vue } from "vue-property-decorator";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
@Component({
  components: {
    ECharts: () => import("vue-echarts/components/ECharts.vue"),
  },
})
export default class DashboardEstadisticas extends Vue {
  public get data_dash() {
    return dieta_visualizacionModule.dash_dieta;
  }

  public get datapie_macros_consulta() {
    return this.CreateChart(
      ["Pr.", "HC.", "Gr."],
      [
        {
          value: this.IsNullZero(this.data_dash.entrevista_porcen_proteinas),
          name: "Pr.",
        },
        {
          value: this.IsNullZero(this.data_dash.entrevista_porcen_glucidos),
          name: "HC.",
        },
        {
          value: this.IsNullZero(this.data_dash.entrevista_porcen_grasa),
          name: "Gr.",
        },
      ],
      DashColors.palettecanadian
    );
  }
  public get macros_consulta_visible() {
    return (
      this.IsNullZero(this.data_dash.entrevista_porcen_proteinas) +
        this.IsNullZero(this.data_dash.entrevista_porcen_glucidos) +
        this.IsNullZero(this.data_dash.entrevista_porcen_grasa) >
      0
    );
  }

  public get datapie_macros_dieta() {
    return this.CreateChart(
      ["Pr.", "HC.", "Gr."],
      [
        {
          value: this.IsNullZero(this.data_dash.proteinas_totales),
          name: "Pr.",
        },
        {
          value: this.IsNullZero(this.data_dash.glucidos_totales),
          name: "HC.",
        },
        { value: this.IsNullZero(this.data_dash.grasas_totales), name: "Gr." },
      ],
      DashColors.palettecanadian
    );
  }

  public get datapie_grasas_dieta() {
    return this.CreateChart(
      ["Saturadas", "Poliinsaturados", "Monoinsaturados"],
      [
        {
          value: this.IsNullZero(this.data_dash.ags),
          name: "Saturadas",
        },
        {
          value: this.IsNullZero(this.data_dash.agpi),
          name: "Poliinsaturados",
        },
        {
          value: this.IsNullZero(this.data_dash.agmi),
          name: "Monoinsaturados",
        },
      ],
      DashColors.palettecanadian
    );
  }

  public get datapie_proteinas_dieta() {
    return this.CreateChart(
      ["Vegetales", "Animales"],
      [
        {
          value: this.IsNullZero(this.data_dash.proteinas_vegetales),
          name: "Vegetales",
        },
        {
          value: this.IsNullZero(this.data_dash.proteinas_animales),
          name: "Animales.",
        },
      ],
      DashColors.palettecanadian
    );
  }

  public get datapie_azucares_dieta() {
    return this.CreateChart(
      ["HC", "Azúcares"],
      [
        {
          value: this.IsNullZero(this.data_dash.glucidos_totales),
          name: "HC",
        },
        {
          value: this.IsNullZero(this.data_dash.azucares),
          name: "Azúcares",
        },
      ],
      DashColors.palettecanadian
    );
  }

  public IsNullZero(valor: any) {
    if (UtilsString.IsNullOrWhiteSpace(UtilsString.ValueOfValue(valor))) {
      return 0;
    }
    if (UtilsString.ValueOf(valor).includes("g")) {
      valor = Number.parseFloat(
        UtilsString.ValueOf(valor).replace("g.", "").trim()
      );
    }
    return valor;
  }

  public CreateChart(legenda: string[], data: any[], paleta: string[]) {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: legenda,
      },
      series: [
        {
          name: "Macros",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          color: paleta,
          data: data,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  }
}
